import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import sourceFileMP4 from "../../assets/video/hero-video.mp4";
import ScrollAnimation from "react-animate-on-scroll";

const propTypes = {
	layout: PropTypes.string,
	video: PropTypes.bool,
};

const defaultProps = {
	layout: "default",
	video: false,
};

const Hero = ({ className, children, ...props }) => {
	const videoRef = useRef();
	const [videoClass, setVideoClass] = useState("video-width");
	const [fileMP4, setFileMP4] = useState("");

	useEffect(() => {
	  setFileMP4(sourceFileMP4);
	}, []);

	useEffect(() => {
		videoRef.current?.load();
	}, [fileMP4]);

	const handleResize = () => {
		if (props.video) {
			const { innerWidth: width, innerHeight: height } = window;

			if (width / height > 1.8) {
				setVideoClass("video-width");
			} else {
				setVideoClass("video-height");
			}
		}
	};

	useEffect(() => {
		window.addEventListener("load", handleResize);
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("load", handleResize);
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<section className={`hero hero__layout-${props.layout} video-${props.video} ${className}`} style={{ backgroundImage: `url(${props.hero})` }}>
			{props.video && (
				<div className="hero__video-wrapper">
					<video autoPlay loop muted playsInline className={`image ${videoClass}`} ref={videoRef}>
						<source id="mp4_src" type="video/mp4" src={fileMP4} />
					</video>
				</div>
			)}
			<ScrollAnimation className="sizing" animateIn="fadeIn" duration={2} animateOnce={true}>
				{children}
			</ScrollAnimation>
		</section>
	);
};


Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
