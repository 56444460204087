import React from "react";
import PropTypes from "prop-types";

// Images
import lineImg from "../../assets/images/home-icon.svg";

const propTypes = {
	image: PropTypes.string,
};

const defaultProps = {
	image: lineImg,
};

const HeroSeparator = ({ className, ...props }) => {
	return (
		<section className={`hero-separator ${className}`}>
			<div className="hero-separator--img">
				<img src={props.image} alt="Icon" />
			</div>
		</section>
	);
};

HeroSeparator.propTypes = propTypes;
HeroSeparator.defaultProps = defaultProps;

export default HeroSeparator;
